$(function() {
    //Begin management of menu display on mobile
    $('.close-menu, #menuOverlay').on('click', function() {
        $('.navbar-collapse').removeClass('active');
        $('#menuOverlay').removeClass('active');
        $('.open-menu').show();
        $('.close-menu').hide();
    });
    $('.open-menu').on("click", function (e) {
        e.preventDefault();
        $('.navbar-collapse').addClass('active');
        $('#menuOverlay').addClass('active');
        // close opened sub-menus
        $('.collapse.show').toggleClass('show');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        $('.open-menu').hide();
        $('.close-menu').show();
    });
    //End management of menu display on mobile
});
